<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import PageHeader from '@components/page-header'
import axios from '@/axios'
import { BASE_IMAGE_URL } from '@src/config/configs'

/**
 * Contents component
 */
export default {
  page: {
    title: 'Contents',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    //  PageHeader
  },
  data() {
    return {
      baseImageUrl: BASE_IMAGE_URL,

      title: 'Contents',
      fighters: [],
    }
  },

  mounted() {
    this.getFighters()
  },
  methods: {
    async getFighters() {
      const data = await axios
        .get('/api/fighter')
        .then((res) => res.data)
        .catch((err) => {
          alert(err.response.data.message)
        })

      this.fighters = data
    },
    async handleClickDeleteFighter(id) {
      var confirmDel = confirm('Are you sure you want to delete?')

      if (confirmDel) {
        await axios
          .delete(`/api/fighter/${id}`)
          .then((result) => {
            this.getFighters()
          })
          .catch((err) => {
            this.$toast.error(err.response.data.error.message)
          })
      }
    },

    async handleClickEditContent(fighter) {
      this.$router.push({
        path: '/fighter/create',
        query: { id: fighter._id },
      })
    },
  },
}
</script>

<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">Fighter List</h4>
      </div>
      <div class="col-md-3 col-xl-6">
        <router-link to="/fighter/create">
          <button class="btn btn-primary float-right">Create</button>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Image</th>
                    <th scope="col">Name</th>
                    <th scope="col"></th>

                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(fighter, index) in fighters" :key="index">
                    <td>{{ ++index }}</td>
                    <td style="width: 20%">
                      <img
                        style="max-width:170px;height:120px;"
                        :src="`${baseImageUrl}/${fighter.fighter_image}`"
                        alt
                        class="img-fluid"
                      />
                    </td>
                    <td style="width: 50%">{{ fighter.fighter_name }}</td>
                    <td>
                      <router-link :to="`/fighter/video/${fighter._id}`"
                        >Add Video</router-link
                      >
                    </td>
                    <td>
                      <button
                        class="btn btn-warning p-0 px-2 py-1 mr-2"
                        @click="handleClickEditContent(fighter)"
                        >Edit</button
                      >
                      <button
                        class="btn btn-danger p-0 px-2 py-1"
                        @click="handleClickDeleteFighter(fighter._id)"
                        >Delete</button
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
